import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _30f9568a = () => interopDefault(import('../pages/bugsnag.vue' /* webpackChunkName: "pages/bugsnag" */))
const _079f5472 = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _02551686 = () => interopDefault(import('../pages/instaredirect.vue' /* webpackChunkName: "pages/instaredirect" */))
const _426a75e2 = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _26a43bba = () => interopDefault(import('../pages/webview/cancel.vue' /* webpackChunkName: "pages/webview/cancel" */))
const _be7a8744 = () => interopDefault(import('../pages/webview/fail.vue' /* webpackChunkName: "pages/webview/fail" */))
const _cf4fca6c = () => interopDefault(import('../pages/webview/logout.vue' /* webpackChunkName: "pages/webview/logout" */))
const _ee56f82c = () => interopDefault(import('../pages/webview/openGiftcard.vue' /* webpackChunkName: "pages/webview/openGiftcard" */))
const _80c1cd7c = () => interopDefault(import('../pages/webview/openSignup.vue' /* webpackChunkName: "pages/webview/openSignup" */))
const _1bb7c582 = () => interopDefault(import('../pages/webview/openSuspend.vue' /* webpackChunkName: "pages/webview/openSuspend" */))
const _049f6357 = () => interopDefault(import('../pages/webview/recaptcha.vue' /* webpackChunkName: "pages/webview/recaptcha" */))
const _7acff31a = () => interopDefault(import('../pages/webview/success.vue' /* webpackChunkName: "pages/webview/success" */))
const _afe3b50a = () => interopDefault(import('../pages/plan/suspend/retain-done.vue' /* webpackChunkName: "pages/plan/suspend/retain-done" */))
const _1c5e5690 = () => interopDefault(import('../pages/warning/external/generic.vue' /* webpackChunkName: "pages/warning/external/generic" */))
const _2a90cc70 = () => interopDefault(import('../pages/webview/partials/account.vue' /* webpackChunkName: "pages/webview/partials/account" */))
const _d1ac93e6 = () => interopDefault(import('../pages/webview/partials/giftcard.vue' /* webpackChunkName: "pages/webview/partials/giftcard" */))
const _8aaa54d4 = () => interopDefault(import('../pages/webview/partials/invite.vue' /* webpackChunkName: "pages/webview/partials/invite" */))
const _0187adec = () => interopDefault(import('../pages/webview/partials/profile.vue' /* webpackChunkName: "pages/webview/partials/profile" */))
const _07eb4c0f = () => interopDefault(import('../pages/webview/partials/qrjockey.vue' /* webpackChunkName: "pages/webview/partials/qrjockey" */))
const _54d07825 = () => interopDefault(import('../pages/webview/partials/signup.vue' /* webpackChunkName: "pages/webview/partials/signup" */))
const _d6dba520 = () => interopDefault(import('../pages/plan/suspend/retain/coupon-offer.vue' /* webpackChunkName: "pages/plan/suspend/retain/coupon-offer" */))
const _29223aa9 = () => interopDefault(import('../pages/plan/suspend/retain/coupon-remember.vue' /* webpackChunkName: "pages/plan/suspend/retain/coupon-remember" */))
const _1c2f0115 = () => interopDefault(import('../pages/plan/suspend/retain/let-go.vue' /* webpackChunkName: "pages/plan/suspend/retain/let-go" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bugsnag",
    component: _30f9568a,
    name: "bugsnag"
  }, {
    path: "/download",
    component: _079f5472,
    name: "download"
  }, {
    path: "/instaredirect",
    component: _02551686,
    name: "instaredirect"
  }, {
    path: "/user/login",
    component: _426a75e2,
    name: "user-login"
  }, {
    path: "/webview/cancel",
    component: _26a43bba,
    name: "webview-cancel"
  }, {
    path: "/webview/fail",
    component: _be7a8744,
    name: "webview-fail"
  }, {
    path: "/webview/logout",
    component: _cf4fca6c,
    name: "webview-logout"
  }, {
    path: "/webview/openGiftcard",
    component: _ee56f82c,
    name: "webview-openGiftcard"
  }, {
    path: "/webview/openSignup",
    component: _80c1cd7c,
    name: "webview-openSignup"
  }, {
    path: "/webview/openSuspend",
    component: _1bb7c582,
    name: "webview-openSuspend"
  }, {
    path: "/webview/recaptcha",
    component: _049f6357,
    name: "webview-recaptcha"
  }, {
    path: "/webview/success",
    component: _7acff31a,
    name: "webview-success"
  }, {
    path: "/plan/suspend/retain-done",
    component: _afe3b50a,
    name: "plan-suspend-retain-done"
  }, {
    path: "/warning/external/generic",
    component: _1c5e5690,
    name: "warning-external-generic"
  }, {
    path: "/webview/partials/account",
    component: _2a90cc70,
    name: "webview-partials-account"
  }, {
    path: "/webview/partials/giftcard",
    component: _d1ac93e6,
    name: "webview-partials-giftcard"
  }, {
    path: "/webview/partials/invite",
    component: _8aaa54d4,
    name: "webview-partials-invite"
  }, {
    path: "/webview/partials/profile",
    component: _0187adec,
    name: "webview-partials-profile"
  }, {
    path: "/webview/partials/qrjockey",
    component: _07eb4c0f,
    name: "webview-partials-qrjockey"
  }, {
    path: "/webview/partials/signup",
    component: _54d07825,
    name: "webview-partials-signup"
  }, {
    path: "/plan/suspend/retain/coupon-offer",
    component: _d6dba520,
    name: "plan-suspend-retain-coupon-offer"
  }, {
    path: "/plan/suspend/retain/coupon-remember",
    component: _29223aa9,
    name: "plan-suspend-retain-coupon-remember"
  }, {
    path: "/plan/suspend/retain/let-go",
    component: _1c2f0115,
    name: "plan-suspend-retain-let-go"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
